// Just a quilt theme definition
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap&subset=latin-ext');

@border-color: #E0E0E0;
@border-color-dark: #161616;
body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: @dark;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Montserrat' !important;
  font-weight: 900;
  color: @brand-primary;
  font-size: 36px;
}


h2 {
  font-family: 'Montserrat' !important;
  font-weight: 900;
  color: @brand-primary;
  font-size: 36px;
}

h3, .cms_module_title {
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 6px;
  position: relative;
  display: inline-block;
}


h3:not(:empty):before, .cms_module_title:not(:empty):before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  border-bottom: 2px solid @brand-primary;
}


.blue {
  background: @brand-primary;
  p {
    color: white;

    h1, h2, h3 {
      color: white;
    }

    a {
      color: white !important;
    }
  }
  i {
      color: white !important;
  }  
}

.gray {
  background: #e9e9e9;
  padding: 32px;

}

.white {
  background: white;
  padding: 32px;

}

.gradient {
  padding: 16px;
  background: rgb(74,198,200);
  background: linear-gradient(0deg, @brand-second 0%, @brand-primary 100%);
  color: white;

  @media (min-width: @screen-sm-min) {
    .icon-container {
      //padding-left: 7% !important;
    }
  }
  @media (max-width: @screen-sm-min) {
    .icon-container .second-part {
      margin-left: 12.5% !important;
      margin-top: 16px;
    }
  }
  .icon-container > div {
      padding: 0 !important;
  }

  .department-icon {
    color: white;
    opacity: 0.6;
    //float: left;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    margin-bottom: 10px;
    img {
      margin-bottom: 10px;
      max-width: 70px;
    }
    width: 25%;
  }
  @media (max-width: @screen-xs-min) {
    .department-icon {
      img {
        max-width: 70%;
      }
    }
  }

  .department-icon:hover, .department-icon.selected-department {
    opacity: 1;
  }
  @media (max-width: @screen-xs-min) {
    .department-icon {
      img {
        max-width: 70%;
      }
    }
  }

  .department-icon.vanjski_logo {
    opacity: 1 !important;
    margin-bottom: 0;
    a {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 100% !important;
    }
  }

}

.cms_module_title {
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 6px;
  position: relative;
  display: inline-block;
}


.cms_module_title:not(:empty):before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  border-bottom: 2px solid @brand-primary;
}

.footer {
  background: @dark;
  color: white;


  h3, .cms_module_title {
    color: white;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 6px;
    position: relative;
    display: inline-block;
  }


  h3:not(:empty):before, .cms_module_title:not(:empty):before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    border-bottom: 2px solid white;
  }

  .bottom {
    background: @darker;
  }
  a {
    color: #aaa !important;
  }
}

.center {
  text-align: center;
}


.container-carousel {
  padding: 0 !important;
}

#cms_area_carousel {
  min-height: 100px !important;
}
.logged-in #cms_area_carousel {
  min-height: 150px !important;
}

.portlet_carousel {

  .empty-carousel {
    position: relative;
    top: 70px;
    z-index: 50;
  }

  position: relative;
  .cms_module_title {
    position: absolute;
  }

  .carousel, .item.active, .item.next, .item.prev {
    height: 500px;
    margin: 0 !important;
  }

  @media (max-width: @screen-sm-max) {
    .carousel, .item.active, .item.next, .item.prev{
      height: 300px;
    }
  }

  @media (max-width: @screen-xs-max) {
    .carousel, .item.active, .item.next, .item.prev {
      height: 200px;
    }
  }

  .carousel-inner img {
    object-fit: cover;
    height: 100%;
  }
  .carousel-caption {
    bottom: 0;
    width: 55%;
    padding: 0 32px;
    text-align: left;
    //background: rgba(255, 255, 255, 0.5);
    //color: black;
    color: white;
    left: 12%;

    p {
      margin-top: -50px;
      margin-bottom: 24px;
      color: white;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-shadow: none;
    }

    a {
      color: white;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-shadow: none;
      text-decoration: none;

    }

    h1 {
      position: relative;
      height: 80px;
      top: -25px;
      color: white;
      font-size: 32px;
      font-weight: 500;
      text-shadow: none;
      margin: 0;
      display: flex;

      a {
        font-size: 32px;
        color: white;
      }
    }
  }
  @media (max-width: @screen-md-min) {
    .carousel-caption {
      width: 70%;
      p {
        display: none;
      }
      h1 {
        top: 0;
        right: 0;
        background: transparent;
      }
    }
  }
  @media (max-width: @screen-sm-min) {
    .carousel-caption {
      width: 80%;
      h1 {
        display: none;
        a {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: @screen-xs-min) {
    .carousel-caption {
      h1 {
        display: none;

        a {
          font-size: 16px;
        }
      }
    }
  }
}

#godina .row {
  padding-bottom: 15px;
  border-bottom: 1px solid gray;
  .col-xs-8 {
    padding-left: 30px;
  }
}

#godina .date-col {
  display: contents;
  #date {
    padding: 20px;
    background: #f0f0f0;
    #day {
      text-align: center;
      font-size: 24px;
    }
}
}
.hamburger {
  @media (min-width: @breakpoint) {
    display: none;
  }
}

.header_logo .pmf_logo {
  max-width: 160px;
  @media (max-width: @screen-xs-min) { 
    max-width: 120px;
  }
}

.department_logo {
  display: inline-flex;
   flex-direction: column; 
   justify-content: space-around;
    padding: 10px 0;
  @media (max-width: @screen-sm-min) { 
    display: none !important;
  }
}

#cms_area_horizontal_nav {
  .portlet_nav {
  /* @media (max-width: @screen-md-max) {
      display: none;
    }*/
    @media (max-width: @breakpoint) {
      display: none;
    }
    color: white;
    a {
      background: transparent;
      color: white;
    }
    a:hover {
      background: rgba(0,0,0,0.3);
      text-decoration: none;
    }
    ul.dropdown-menu {
      background: rgba(0,0,0,0.6);
    }
  }
}

#cms_area_left {
  .portlet_nav {
    .navbar-header {
      display: none;
    }
    .navbar-collapse {
      padding-left: 0 !important;
    }
    .nav_link {
      color: @gray-light;
      width: 90% !important;
      text-transform: lower!important;
      min-height: 30px;
    }
    .nav_link:active,
    .nav_link:focus,
    .nav_link:hover,
    .nav_link:visited {
      background-color: transparent !important;
    }
    .collapse-nochildren {
      display: none;
    }
    .collapse-link {
      min-height: 30px;
      float: right;
    }
    .collapse-link:active,
    .collapse-link:focus,
    .collapse-link:hover,
    .collapse-link:visited {
      background-color: transparent !important;
    }
    .nav-element {
      min-height: 30px;
      .collapse-nochildren {
        float: right;
        .fa-angle-right {
          display: none;
        }
      }
      .collapse-link {
        min-height: 30px;
        float: right;
      }
      .collapse-link:active,
      .collapse-link:focus,
      .collapse-link:hover,
      .collapse-link:visited {
        background-color: transparent !important;
      }
      //color: @gray-light;
      border-bottom: 1px solid @gray-lighter;
      margin-bottom: 1px;
      text-transform: uppercase!important;
      &:hover {
        background-color: transparent !important;
        color: lighten(@brand-primary, 0.8);
        /*border-bottom: 2px solid lighten(@brand-primary, 0.8);*/
        margin-bottom: 1px;
      }
      &.active>a {
        color: lighten(@brand-primary, 0.8);
      }
      &.active>a>.fa {
        color: @gray-light;
      }
      &.hover>a.hover {
        color: green !important; //
      }
      &>a.collapse-link.collapsed i {
        -webkit-transform: rotate(1deg);
        -moz-transform: rotate(1deg);
        -ms-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        transform: rotate(1deg);
      }
      &>a.collapse-link i {
        -webkit-transform: rotate(89deg);
        -moz-transform: rotate(89deg);
        -ms-transform: rotate(89deg);
        -o-transform: rotate(89deg);
        transform: rotate(89deg);
      }
    }
    ul > li > ul > .nav-element {
      text-transform: none !important;
    }

    .public_page.present_page {
      border-left: 2px solid green;
      border-right: none;
    }
    .public_page.not_present_page {
      border-left: 2px dotted green;
      border-right: none;
    }
    .private_page.present_page {
      border-left: 2px solid red;
      border-right: none;
    }
    .private_page.not_present_page {
      border-left: 2px dotted red;
      border-right: none;
    }
    ul {
      list-style: none;
      //margin-top: 10px;
    }
    .nav-element>ul {
      padding-left: 0;
      //margin-top: 10px;
    }
    .nav-element>ul>li>a {
      min-height: 30px;
    }
    .nav-element>ul li.nav-element {
      min-height: 30px;
      //padding: 5px 10px;
      padding: 5px 0px 5px 15px;
      .collapse-nochildren {
        float: right;
      }
      .collapse-link {
        min-height: 30px;
        float: right;
        position: absolute;
        right: 0;
      }
      .collapse-link:active,
      .collapse-link:focus,
      .collapse-link:hover,
      .collapse-link:visited {
        background-color: transparent !important;
      }
      border-bottom: none;
      margin-bottom: 0px;
      &:focus {
        background-color: transparent;
        color: lighten(@brand-primary, 0.8);
        border-bottom: none;
      }
      &:hover {
        background-color: transparent;
        color: lighten(@brand-primary, 0.8);
        border-bottom: none;
      }
      a {
        color: @gray-light;
        //padding: 10px;
      }
    }
    .nav>li>a {
      color: @gray-light;
      &:first-child {
        padding-left: 10px;
        padding-right: 0px;
      }
      &:focus {
        color: lighten(@brand-primary, 0.8);
      }
    }
    .nav>li>ul>li {
      a:first-child {
        padding-left: 10px !important;
      }
      a {
        padding-left: 5px !important;
        //text-indent: 50px!important;
      }
      a {
        //text-transform: uppercase;
        color: @gray-light;
        //padding-left: @nav-link-sublist-element-padding-left;
        &:focus {
          background-color: transparent;
          color: lighten(@brand-primary, 0.8);
          border-bottom: none;
        }
        &:hover {
          background-color: transparent;
          color: lighten(@brand-primary, 0.8);
          border-bottom: none;
        }
      }
    }
    .nav>li>ul>li>ul>li {
      a:first-child {
        padding-left: 10px !important;
      }
    }
    .nav>li>ul>li>ul>li>ul>li {
      a:first-child {
        padding-left: 10px !important;
      }
    }
    .nav>li>ul>li>ul>li>ul>li>ul>li {
      a:first-child {
        padding-left: 10px !important;
      }
    }
    li.active {
      //background-color: @brand-primary-light4 !important;
      &:hover,
      &:focus {
        a:focus,
        a:hover {
          color: @brand-primary !important;
        }
        //background-color: @brand-primary-light4 !important;
      }
      &>a {
        color: lighten(@brand-primary, 0.8) !important;
        &:focus,
        &:hover {
          color: lighten(@brand-primary, 0.8) !important;
        }
      }
      &>ul,
      li {
        color: @gray-light;
        background-color: white;
        &:hover,
        &:focus {
          a:focus,
          a:hover {
            color: lighten(@brand-primary, 0.8) !important;
          }
          background-color: white !important;
        }
        &.active {
          //background-color: @brand-primary-light4;
          &:hover,
          &:focus {
            a:focus,
            a:hover {
              color: @brand-primary;
            }
            //background-color: @brand-primary-light4 !important;
          }
        }
      }
    }
    li {
      //background-color: transparent;
      &>a {
        color: @gray-light;
        //background-color: transparent;
        &:focus,
        &:hover {
          color: @gray-light;
        }
      }
      &>ul,
      li {
        color: @gray-light;
        //background-color: transparent;
      }
    }
  } //end portlet nav
}


#cms_area_horizontal_nav {
  .nav-element>a.nav_link {
    border: 0!important;
    font-size: 14px;
  }
  .alignleft {
    min-height: 45px;
  }
  .nav-element {
    &:last-child{
      margin-right: 0px!important;
    }
  }

  .dropdown-menu {
    border: 1px solid green;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .mega-dropdown-menu li a:hover,
  .mega-dropdown-menu li a:focus {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .navbar-default .navbar-nav>li:hover {
    background-color: #000!important;
    color: white!important;
  }
  .navbar-nav {
    position: relative;
    margin-top: 7px;
  }
  .navbar-default .navbar-nav>li>a {
    &:hover {
      color: darken(white, 15%)!important;
    }
  }
  .dropdown-menu {
    width: 100%;
    min-height: 300px;
    border: 0!important;
    padding: 0!important;
    border-radius: 0;
  }

  .dropdown-menu.mega-dropdown-menu a {
    padding: 13px 25px;
    border-right: 0!important;
    font-weight: bolder !important;
  }
  .dropdown-menu.mega-dropdown-menu a {
    &:visited,
    &:hover,
    &:focus,
    &:active {
      padding: 13px 25px;
      border-right: 0!important;
      font-weight: bolder !important;
      color:white!important;
    }
  }

  .mega-dropdown-menu li {
    width: 100%;
    a {
      width: 100%;
    }
  }
  .nav-element > a.nav_link {
    &:visited,
    &:hover,
    &:focus,
    &:active {
      border: 0!important;
      font-size: 14px!important;
    }
  }
  .dropdown.mega-dropdown.nav-element {
    position:static!important;
    >a{
      position:relative!important;
    }
    >ul {
      position:absolute!important;
      left:0!important;
    }
  }

  .main-nav-html {
    position:relative;
    float:right;
  }

  .portlet_admin_pagelay .main-nav-html {
    float: none;
  }
  .mega-dropdown-menu {
    background: rgba(0, 0, 0, 0.3);
    //position: relative;
    .menutitle {
      display: none !important;
    }
    .description {
      display: none !important;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    .alignleft {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      min-height: 50px;
      float: none;
      width: 99%;
      //margin-right:10%;
      position: relative;
    }
    li {
      margin: 0;
      padding: 0;
    }
    a {
      color: white;
    }
  }
    /*.bzvz {
        /* Safari and Chrome 
        -webkit-animation:mymove 0.1s;
        //-webkit-animation-iteration-count:3;
        }

        @keyframes mymove
        { from {top:120px; opacity: 0;}
        to {top:0px; opacity: 1}
        }

        @-webkit-keyframes mymove /* Safari and Chrome 
        {
        from {top:120px; opacity: 0;}
        to {top:0px; opacity: 1;}
        }*/

}
// end horizontal nav


.footer {
  margin-top: 32px;
  .bottom {
    padding: 10px 0;
    p {
      margin: 10px 0;
    }
  }
}




.portlet_news_show_headlines {
  .card {
    position: relative;
    padding: 10px;
    min-height: 160px;
    @media (max-width: @screen-sm-max) {
      height: auto !important;
      min-height: auto !important;
    }
    margin: 0;
    margin-bottom: 40px;
    h4.card-title {
      a {
        color: #0b0c10;
      }
      a:hover {
        color: @brand-primary;
        text-decoration: none;
      }
    }
    .news_show_headlines_render_articles {
        height: 70px;
        @media (max-width: @screen-sm-max) {
          height: auto !important;
          min-height: auto !important;
        }
    }
    .news-header {
      display: block;
      float: left;
      margin: 0;
      background: silver;
      color: white;
      text-transform: uppercase;
      font-size: 11px;
      padding: 5px 10px;
    }

    .more {
      //position: absolute;
      //bottom: 10px;
      //left: 10px;
      //right: 10px;
      padding-top: 10px;
      border-top: 1px solid @brand-primary;
      a {
        color: @brand-primary;
      }
    }
    &.big {
      margin: 0 !important;
      .news_show_headlines_render_articles {
        @media (max-width: @screen-sm-max) {
          padding: 20px 0;
        }
      }
    }

    .biol {background: #2a8f63}
    .chem {background: #6d5160}
    .geof {background: #cc6d51}
    .geog {background: #f8464a}
    .geol {background: #ffcc99}
    .math {background: #00dddd}
    .phy {background: #52595d}
    .pmf {background: #2aadaf}

  }
  .card.big {
    height: 400px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    .img {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
}

.portlet_news {
  .author {
    display: none;
  }
  h1 {
    font-weight: 600;
    font-size: 20px;
    a:hover {
      text-decoration: none;
    }
  }
  .lead {
    font-size: 16px;
  }
  .news_article {
    padding-bottom: 20px;
  }
}


.white-layer {
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(80,80,80,0.4);
  z-index: 2;
}

.portlet_search .search_result {
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #b5b5b5;
  .search_result_title {
    display: inline-block;
    float: left;
    width: 80%;
    margin: 4px 0 2px;
    font-size: 18px !important;
  }
  .search_result_headline {
    padding-top: 12px;
    clear: both;
    text-align: left
  }
  .search_result_subline {
    font-style: italic;
  }

}
.portlet_ispis_pred .table-responsive {
//treba napraviti stiliziranje

}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  .header-nav {
    text-align: right;
  }

  .header_content {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
  }

  .header-icons {
    display: flex;

    @media (max-width: @screen-xs-max) {
      flex-wrap: wrap;
    }

    justify-content: space-between;
    align-items: center;
    color: white;

    img.burger {
      height: 35px;
      padding: 5px;
      margin: 5px;
    }
    .lang_links {
      margin: 10px;
      padding-top: 5px;
      text-decoration: none;
      font-size: 16px;
      @media (max-width: @screen-xs-max) {
        font-size: 14px;
	margin: 5px;
      }
      a {
        text-decoration: none;
      }
    }
    .login_icon {
      width: 24px;
      margin: 10px;
      margin-right: 0;
      @media (max-width: @screen-xs-max) {
        width: 20px;
        margin: 5px;
      }

    }
    // autocomplete search

    .div_autocomplete {
      float: right;
      margin-top: 30px;
      @media (max-width: @screen-sm-max) {
        display: none !important;
      }
    }
    .webshop {
	padding-top: 7px;
	a {
	  padding: 10px;
	  font-size: 18px;
	  color: white;
          @media (max-width: @screen-xs-max) {
            font-size: 14px;
            padding: 5px;
          }
	}
    }

    .search-separator .search-container {
    }

    .search-separator .search-container:hover input {
      min-width: 350px !important;
    }

    .search-separator .search-container:hover .search-button {
      color: @brand-primary !important;
      background-color: transparent !important;
    }

    .search-separator .search-container input {
      color: @brand-primary !important;
      background-color: white !important;
    }

    .search-separator .search-container input:focus {
      min-width: 350px !important;
    }

    .search-separator .search-button {
      background-color: transparent !important;
      border: 0 !important;
      padding: 12px !important;
    }

    #unibox-suggest-box {
      background: rgba(0,0,0,0.7) !important;
    }
  }
  .header-logo {
    color: white;
    text-transform: uppercase;
    img {
      margin: 8px 0;
      max-height: 90px;
    }
  }
  .lang_links {
    a {
      color: white;
    }
    .current_lang {
      display: none;
    }
    .full-lang-name {
      display: none;
    }
    .w3c-lang-name {
      text-transform: uppercase;
      display: inline !important;
    }
  }

    #accessibility_opener {
      display: inline-block;
      img {
        display: none
      }
      font-size: 0px;
      color: transparent;
      cursor: pointer;
      height: 35px;
      width: 35px;
      background: url('../../icons/accessibility.png');
      background-size: cover;
      margin: 10px;
      @media (max-width: @screen-xs-max) {
        height: 25px;
        width: 25px;
        margin: 5px;
      }

    }


}

.accessibility_dialog {
  max-width: 320px;
  width: 320px !important;
  font-family: inherit;
  color: @text-color-lighten;
  background: white;
  border: 0;
  padding: 15px;
  z-index: 9999;
  -webkit-box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.1);
  -moz-box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.1);
  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.1);

  .ui-dialog-title{
    color: @text-color-lighten;
    text-transform: uppercase;
  }
  .ui-dialog-titlebar {
    border: none !important;
  }

  .ui-dialog-titlebar-close {
    .glyphicon;
    .glyphicon-remove;
    width: 24px;
    height: 24px;
    color: @text-color-lighten;
    font-size: 12px;
    font-weight: normal;
    padding: 1px;
    padding-top: 3px;
    &::before {
      position: relative;
      left: -1px;
    }
  }


  #accessibility_links {
    max-width: 280px;
    .textsize {
      .gornjilinkoviboxVelicina {
        padding: 10px;
        font-weight: 800;
      }
      .select_size_0 {
        font-size: 11px !important;
      }
      .select_size_1 {
        font-size: 13px !important;
      }
      .select_size_2 {
        font-size: 15px !important;
      }
    }
    .highcontrast {
      .highcontrast_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
    .simplelayout {
      .simplelayout_label {
        float: left;
        width: 90%;
      }
      a {
        float: left;
        width: 90%;
        margin-left: 30px;
      }
    }
  }
}

//Accessibility text size:
body.textsize-big {
  font-size: 20px;

  .accessibility_links, .ui-dialog-title {
    font-size: 16px !important;
    }

  #footer_container {
    font-size: 18px;
    #title_footer_title {
      font-size: 24px;
    }
  }

  .header_columns {
    .header_columns_title {
      @media (min-width: @screen-lg) {
        margin-top: 8px;
      }
      @media (max-width: @screen-md-max) {
        margin-top: -8px;
      }
      @media (max-width: @screen-sm-max) {
        margin-top: 8px;
      }
      @media (max-width: 400px) {
        margin-top: 0px;
      }
    }
    .header_title {
      @media (min-width: @screen-sm-max) {
        font-size: 18px;
      }
      @media (max-width: @screen-sm-max) {
        font-size: 16px;
      }
    }
    .header_title_big {
      margin-top: 0;
      @media (min-width: @screen-md) {
        margin-top: 6px;
      }
      @media (max-width: @screen-sm-max) {
        //font-size: @font-size-h3;
      }
    }
  }

  #header-search {
    .header-search_input {
      font-size: 20px;
    }
  }
  #header_icon_search {
    margin-top: 7px;
    div {
      height: 32px;
      width: 32px;
      i {
        font-size: 22px;
      }
    }
  }

  .top_link_row > .top_link{
    .fa {
      font-size: 16px;
    }
    a {
      font-size: 16px;
    }
  }

  #cms_area_horizontal_nav {
    .navbar-nav > .nav-element > a {
      font-size: 18px;
    }
  }

 .news_meta_tooltip {
    display: none !important;
  }

  #cms_area_right, #cms_area_left .portlet_nav .nav-element {
    .nav_link {
      font-size: 22px !important;
      padding: 8px;
    }
    .collapse-nochildren, .collapse-link {
      position: absolute;
      right: 0;
    }
    li > ul li {
      padding-top: 0;
    }
    ul > li > a {
      font-size: 22px !important;
      padding: 8px;
    }
  }
  .cms_module_title {
    font-size: 24px;
  }

  .portlet_news {
   .author {
      display: none;
    }
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 24px !important;
      }
    }
  }
  
  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 36px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 18px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p a{
    font-size: 18px !important;
  }
  /*#cms_area_header .carousel-inner .item .carousel-caption h1 {
    font-size: 32px;
  }
  #cms_area_header .carousel-inner .item .carousel-caption p {
    font-size: 18px;
  }*/
  #cms_area_middle_right .item .carousel-caption .truncate-to-visible {
    max-height: 60px;
  }

  /*#cms_area_middle .portlet_news_show_headlines .news_headlines .article {
    height: 160px;
  }
  #cms_area_middle .portlet_news_show_headlines:first-child .news_headlines .article {
    height: 160px;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption h3 .title {
    height: 90px;
  }

  #cms_area_middle .portlet_news_show_headlines:first-child .news_headlines {
    height: 100px;
    @media (max-width: @screen-sm-max) {
      height: 500px;
    }
  }
  #cms_area_middle .portlet_news_show_headlines:first-child .news_headlines .article .caption h3 .title {
    height: 60px;
  }*/
   #cms_area_middle_gray1 .portlet_news_show_headlines .big {
    //height: 80px;
    @media (max-width: @screen-xs) {
      height: 525px !important;
    }
    @media (min-width: 481px) {
      height: 600px !important;
    }
   
    @media (min-width: 600px) {
      height: 700px !important;
    }
    
    @media (min-width: @screen-sm) {
      height: 400px !important;
    }
  
  }
  
  #cms_area_middle_gray1 .portlet_news_show_headlines .card-title  {
    font-size: 22px;    
    }
   /* 
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    font-size: 20px;
  }
    
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    width: 110px;
    padding: 2px;
  }
    */
  .ui-tabs > .ui-tabs-nav > li.ui-state-default > a {
    font-size: 15px;
  }

  .table > .predmet > td {
    font-size: 16px !important;
  }

  .bottom_links_1, .bottom_links_2 {
    a {
      font-size: 12px
    }
    strong {
      font-size: 18px;
    }
  }



  #cms_area_middle_right {
    .cms_module_title {
      font-size: 20px;
    }
    .item .carousel-caption .title a {
      font-size: 24px;
    }
    .item .carousel-caption .date {
      font-size: 20px;
    }
  }
}//end of .textsize-big

body.textsize-small {
  font-size: 14px;
  #footer_container {
    font-size: 12px;
    #title_footer_title {
      font-size: 18px;
    }
  }

  .header_columns {
    .header_columns_title {
      @media (min-width: @screen-lg) {
        margin-top: 8px;
      }
      @media (max-width: @screen-md-max) {
        margin-top: -8px;
      }
      @media (max-width: @screen-sm-max) {
        margin-top: 20px;
      }
    }
    .header_title {
      @media (min-width: @screen-sm-max) {
        font-size: 12px;
      }
      @media (max-width: @screen-sm-max) {
        font-size: 10px;
      }
    }
    .header_title_big {
      @media (min-width: @screen-md) {
        margin-top: 20px;
      }
      @media (min-width: @screen-sm-max) {
        font-size: @font-size-h4;
      }
      @media (max-width: @screen-sm-max) {
        font-size: 24px;
      }
      @media (max-width: 440px) {
        font-size: 20px;
      }
    }
  }

  #header-search {
    .header-search_input {
      font-size: 14px;
    }
  }
  #header_icon_search {
    margin-top: 3px;
    div {
      padding-top: 1px;
      i {
        font-size: 20px;
      }
    }
  }

  .top_link_row > .top_link{
    .fa {
      font-size: 12px;
    }
    a {
      font-size: 12px;
    }
  }

  #cms_area_horizontal_nav {
    .navbar-nav > .nav-element > a {
      font-size: 12px;
    }
  }

  #cms_area_right, #cms_area_left .portlet_nav .nav-element {
    min-height: 24px;
    .nav_link {
      font-size: 16px !important;
      padding: 8px;
    }
    .collapse-nochildren, .collapse-link {
      position: absolute;
      right: 0;
    }
    li > ul li {
      padding-top: 0;
    }
    ul > li > a {
      font-size: 16px !important;
      padding: 8px;
    }
  }

  .cms_module_title {
    font-size: 16px;
  }

  .portlet_news {
    .news_article_title h1 {
      .news_title_truncateable {
        font-size: 18px !important;
      }
    }
  }
  #cms_area_carousel .portlet_carousel .carousel-caption h1 {
    font-size: 20px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p {
    font-size: 12px;
  }
  #cms_area_carousel .portlet_carousel .carousel-caption p a{
    font-size: 12px !important;
  }
  .gradient .icon-container .department-icon {
     font-size: 12px !important;    
  }
   /* 
  #cms_area_header .carousel-inner .item .carousel-caption h1 {
    font-size: 20px;
  }
  #cms_area_header .carousel-inner .item .carousel-caption p {
    font-size: 12px;
  }
    */
  #cms_area_middle_right .item .carousel-caption .truncate-to-visible {
    max-height: 60px;
  }
  #cms_area_middle_gray1 .portlet_news_show_headlines .big {
    //height: 80px;
    @media (max-width: @screen-xs) {
      height: 525px !important;
    }
    @media (min-width: 481px) {
      height: 600px !important;
    }
   
    @media (min-width: 600px) {
      height: 700px !important;
    }
    
    @media (min-width: @screen-sm) {
      height: 400px !important;
    }
  }
  /*#cms_area_middle_gray1 .portlet_news_show_headlines .card {
    height: 80px;
  }
  #cms_area_middle_gray1 .portlet_news_show_headlines .card-title {
    height: 38px;
  }
  #cms_area_middle_gray1 .portlet_news_show_headlines:first-child .news_headlines {
    height: 140px;
    @media (max-width: @screen-sm-max) {
      height: 480px;
    }
  }
  #cms_area_middle_gray1 .portlet_news_show_headlines .card-big .card-title {
    height: 60px;
  }*/
  #cms_area_middle_gray1 .portlet_news_show_headlines .card-title  {
    font-size: 14px;    
    }
  #cms_area_middle_gray1 .portlet_news_show_headlines .card-content  {
    font-size: 12px;    
    }
  /* #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    font-size: 14px;
  }

  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    width: 80px;
  }*/

  .ui-tabs > .ui-tabs-nav > li.ui-state-default > a {
    font-size: 15px;
  }

  .table > .predmet > td {
    font-size: 16px !important;
  }

  .bottom_links_1, .bottom_links_2 {
    a {
      font-size: 12px
    }
    strong {
      font-size: 18px;
    }
  }

  #cms_area_middle_right {
    .cms_module_title {
      font-size: 14px;
    }
    .item .carousel-caption .title a {
      font-size: 18px;
    }
    .item .carousel-caption .date {
      font-size: 14px;
    }
  }
}
//END accessibility text size

//accessibility simplelayout
body.simplelayout {
  .container-carousel {
    display: none;
  }
  .gradient {
        padding-top: 150px;
  }
  #cms_area_middle_right {
    .portlet_news_show_headlines {
      script {
        display: none;
      }
    }
  }
  .portlet_news_show_headlines {
    .article {
      .make-md-column(12);
    }
  }
}
//END accessibility simplelayout

//accessibility contrast
body.highcontrast-normal {
  color: black !important;
  a {
    color: darken(@brand-primary, 50%);
  }
  .header_columns {
    color: black;
  }
  .top_link_row > .top_link a {
    color: black;
  }
  .accessibility_dialog {
    color: black;
    .ui-dialog-title{
      color: black;
    }
    .ui-dialog-titlebar-close {
      color: black;
    }
  }

  #accessibility_opener, .header .header-icons .login_icon {
    filter: invert(1);
  }
  .horizontal-nav-row {
    color: black !important;
  }
  .portlet_nav {
    color: black !important;
    .nav > li > a {
      color: black !important;
    }
    .nav > li > ul > li a {
      color: black !important;
    }
  }
  .header-search_input {
    border: 1px solid @border-color !important;
    color: black;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: black !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: black !important;
      .truncate-to-visible {
        color: black !important;
      }
    }
  }
  .carousel-caption {
    color: black !important;
    .truncate-to-visible {
      color: black !important;
    }
  }
  .item .carousel-caption .title a {
    color: black !important;
  }
  .item .carousel-caption h1 a {
    color: black !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: black !important;
  }
  .bottom_links_1 .col-xs-12 > div, .bottom_links_2 .col-xs-12 > div {
    background-color: @brand-primary-light-full-transparent;
    //color: black !important;
  }
  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: black !important;
    font-weight: 800;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: black !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: black !important;
  }
  #footer_container {
    background-color: @border-color-light;
  }
  #footer_container #footer_header {
    color: black !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: black !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: black !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: black !important;
  }
  .mega-dropdown-menu {
    background: white !important;
  }
  .search-button, .lang_link {
    color: black !important;
  }
  .div_login img, .hamburger img {
   filter: invert(100%);
  }
  .mm-listview>li>a, .mm-listview>li>span {
    color: black !important;
  }
  #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:active, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:focus, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu  a:hover, #cms_area_horizontal_nav .dropdown-menu.mega-dropdown-menu a:visited {
     color: black !important;
  }
  .header .header-icons .search-separator .search-container input {
     border: 1px solid black; 
     background-color: white !important;
     color: black !important;
  }
}
//END accessibility contrast

//accessibility inverted contrast
body.highcontrast-inverted {
  color: white !important;
  background-color: @text-color;
  a {
    color:  white !important;
  }
  .popup_menu {
    background-color: #777 !important;
  }
  .portlet_admin_traka {
    .dropdown-menu {
      background-color: #777 !important;
      > li > a {
        background-color: #777 !important;
      }
    }
  }
  .logo_div {
    img.normal {
      display: none;
    }
    img.white {
      display: block;
    }
  }
  .header_columns {
    color: white !important;
  }
  .top_link_row > .top_link a {
    color: white !important;
  }
  .ui-widget-content {
    background-color: #777 !important;
  }
  .accessibility_dialog {
    span {
      color: white !important;
      font-weight: 800;
    }
    color: white !important;
    .ui-dialog-title{
      color: white !important;
    }
    .ui-dialog-titlebar-close {
      //color: white !important;
    }
  }
  .horizontal-nav-row {
    color: white !important;
  }
  .portlet_nav {
    color: white !important;

    .nav > li > a {
      color: white !important;
    }
    .nav > li > ul > li a {
      color: white !important;
    }
    .nav {
      background-color: transparent !important;
    }
  }
  .nav ul li, .nav ul li:hover {
    background-color: #333 !important;
  }

  #cms_area_left .portlet_nav .nav_link:hover, #cms_area_left .portlet_nav .collapse-link:hover,  #cms_area_left .portlet_nav .nav-element .collapse-link:hover, #cms_area_left .portlet_nav .nav-element:hover { 
    background-color: #333 !important;
  }


  
  .header-search_input {
    border: 2px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }
  .cms_module_title {
    color: white !important;
  }
  .portlet_news_show_headlines {
    .carousel-caption {
      color: white !important;
      .truncate-to-visible {
        color: white !important;
      }
    }
  }
  .carousel-caption {
    color: white !important;
    .truncate-to-visible {
      color: white !important;
    }
  }
  .carousel {
    border-color:  #777 !important;
    .item {
      background-color: #777 !important;
    }
  }
  input, select {
    //border: 2px solid @brand-primary;
    //background-color: @brand-primary;
    color: @border-color-dark;
  }
  tr {
    background-color: darken(@border-color, 60%)  !important;
    color: white;
  }
  tr:hover {
    background-color: darken(@border-color, 60%)  !important;
    color: white !important;
    a, a:hover, a:active, a:focus {
      color: white !important;
      font-weight: 800 !important;
    }
  }
  tr:nth-of-type(2n+1) {
    background-color: darken(@border-color, 30%)  !important;
    color: white;
  }
  tr:nth-of-type(2n+1):hover {
    background-color: darken(@border-color, 30%)  !important;
    color: white;
    a, a:hover, a:active, a:focus {
      font-weight: 800 !important;
    }
  }
  .predmetispis_lighter {
    color: white;
  }
  .predmetispis_lighter:hover {
    font-weight: bold;
  }
  .item .carousel-caption .title a {
    color: white !important;
  }
  .item .carousel-caption h1 a {
    color: white !important;
  }
  div.portlet_repository span.cms_info_about_authortime {
    color: white !important;
  }
  .bottom_links_1 .col-xs-12 > div, .bottom_links_2 .col-xs-12 > div {
    background-color: @brand-primary-darker;
    //color: black !important;
    font-weight: 800;
  }
  .middle-left-col .portlet_content .main_page_upper_links a, .middle-left-col .portlet_content .main_page_upper_links a:focus, .middle-left-col .portlet_content .main_page_upper_links a:active, .middle-left-col .portlet_content .main_page_upper_links a:hover, .middle-left-col .portlet_content .main_page_upper_links a:visited {
    color: white !important;
    font-weight: 800;
    background-color: #333 !important;
  }
  .portlet_news_show_headlines .news_headlines .article .thumbnail {
    background-color: transparent !important;
  }
  .portlet_news_show_headlines .news_headlines .article .caption h3 a {
    color: white !important;
  }
  #cms_area_middle .portlet_news_show_headlines .news_headlines .article .caption time {
    color: white !important;
  }
  #footer_container {
    background-color: transparent;
  }
  #footer_container #footer_header {
    color: white !important;
  }
  #footer_container #footer_header strong, #footer_container #footer_row h3 {
    color: white !important;
    font-weight: 800;
  }
  #footer_container #footer_row {
    color: white !important;
  }
  #footer_container #footer_row ul li a, #footer_container #footer_row ul li a:visited {
    color: white !important;
  }
   
  .gray, .white, .blue, .footer, .white-layer, .mega-dropdown-menu, .gradient, #mmenu_init {
      background-color: #333 !important;
  }

}
//END accessibility inverted contrast

.logged-in .header, .logged-in .white-layer {
  top: 50px;
}

.portlet_repository {
  ul {
    list-style-type: none;
    padding-left: 20px;
    img {
      float: left;
      margin-right: 10px;
    }
    li {
      padding: 5px 0;
    }
    .cms_info_about_authortime {
      font-size: 12px;
      font-style: italic;
    }
  }
  .repository > ul {
    padding: 0;
    margin: 0;
  }
}

.portlet_djelatnici, .portlet_ispis_pred, .portlet_predmet_info {
  .ui-widget {
    font-family: inherit !important;
    font-size: inherit !important;
  }
}

.portlet_djelatnici {
  p.person, p.text-small.text-ellipsis {
	display: inline-block !important; 
	white-space: nowrap;
  }
  p.person {
  	min-width:300px;
  }
}

.portlet_ispis_pred {
  table {
    width: 100%;
  }
  .ects {
    width: 50px;
  }
  .semestri  {
    width: 70px;
  }
  .opterecenja {
    width: 100px;
  }
  .info {
    width: 50px;
  }
  .th td {
    background: #e0e0e0 !important;
  }
  th {
    padding: 5px;
    padding-top: 30px;
    font-size: 16px;
  }
}

.portlet_portfolio {
  table.portfolio td {
    padding: 8px;
  }
  tr:nth-child(odd) td {
    background: #f5f5f5;
  }

  td.data_label {
    font-weight: bold;
    text-align: right;
    padding-right: 24px;
  }
  .cms_module_title {
    display: none;
  }
  .admin_toolbox {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    margin: 30px 0;
    border-bottom: 1px solid silver;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }

}

#cms_area_middle_gray2 {

  .discover {
    .text {
      padding-left: 25px;
    }

    a {
      display: flex;
      color: black;
      text-decoration: none;
      justify-content: left;
      align-items: center;
    }

    .icon {
      height: 74px;
      width: 75px;
      background: url("../../icons/icon-map-large.png");
      background-position-y: top;
      transition: 0.5s;

      &.map {
        background: url("../../icons/icon-map-large.png");
      }

      &.events {
        background: url("../../icons/icon-events-large.png");
      }

      &.calendar {
        background: url("../../icons/icon-map-large.png");
      }
    }

    a:hover .icon {
      background-position-y: bottom !important;
    }
  }
}

.cms_table_row_1 td, .cms_table_row_highlight_1 td {
       background: #F0F0F0;
       padding: 5px;
     }
.cms_table_row_0 td, .cms_table_row_highlight_0 td{
  background: white;
  padding: 5px;
}

.rss_client_item {
  padding: 15px 0;
  border-bottom: 1px solid gray;
}
.rss_client_title {
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 15px;
}
.rss_client_description {
  padding: 15px 0;
}
.portlet_content {
    table {

     width:100% !important;
     max-width:100%;
     display: table;
     border-collapse: collapse;
     color: #212121 !important;
     background:transparent;
     tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

       td, th {
        padding:8px !important;
        line-height: 1.42857143 !important;
        color: #212121 !important;
        border: 1px solid #ddd !important;
        border-radius: 5px;
               }

        tr {
          display: table-row ;
          vertical-align: inherit;
          border-color: inherit;
         
            }
                   
              }
     }
     table>tbody>tr:nth-of-type(odd) {
      background-color: #f9f9f9;
       } 
     table>tbody>tr:hover{background: #f9f9f9;}

}

.portlet_search {
  .search_result {
    padding: 10px 0;
  }
  .resultsItem {
    padding: 3px 0;
    .icon {
      display: inline-block;
      margin-right: 10px;
    }
    .title {
      display: inline-block;
    }
  }
}

#unibox-suggest-box {
  a {
    padding: 10px;
  }
  .search_icon, .search_txt {
    display: inline-block;
    padding: 0;
    padding-right: 10px;
  }
}
